import { switchOrAddNetwork, type WalletProvider } from '../config'
import { type Config, type SupportedBlockchain } from '../../../types'
import { isMobile } from 'react-device-detect'

const walletProvider = (): WalletProvider => {
    let network: SupportedBlockchain
    let accountId: string = ''
    let isConnected: boolean = false
    let provider
    
    const switchAccount = async () => {
      try {
        const accounts = await provider.request({
            "method": "wallet_requestPermissions",
            "params": [
              {
                "eth_accounts": {}
              }
            ]
          })
        accountId = accounts[0]
        return accounts;
      }catch(error: any) {
        // Coinbase wallet does not support wallet_requestPermissions and returns different errors to the same case
        const errorMessage = error?.message.toLowerCase()
        const isUnsupported = [
          "not available",
          "not supported",
          "unsupported",
        ].some((filter) => errorMessage.includes(filter));
        if (isUnsupported) {
          console.warn("wallet_requestPermissions not supported")
          return
        }
        throw error
      }
    }
    const getCurrentAccount = async (sdk) => {
        if (!isMobile) {
            const accounts = await provider.request({
                method: 'eth_requestAccounts',
                params: [],
            })
            return accounts[0]
        }
        // tweak IOS MM app. 
        let account = sdk?.activeProvider?.getState?.().accounts?.[0]
        
        if (!account){
            account = await sdk?.connect()
            return account[0]
        }
        return account
    }

    const connectWallet = async (
      selectedBlockchain: SupportedBlockchain,
      walletProvider: any,
      _index?: number,
      sdk?: any,
      cfg?: Config,
      isAccountSwitch?: boolean
    ) => {
      provider = walletProvider;
      if (!cfg) return;
      await switchOrAddNetwork(selectedBlockchain, walletProvider, cfg);
      network = selectedBlockchain;

      if (isAccountSwitch) {
        await switchAccount();
      }

      // TODO: We should remove the sdk line as we are not restrict to MM sdk
      accountId =
        sdk.getProvider()?.getSelectedAddress() ??
        (await getCurrentAccount(sdk).catch((error) => {
          console.error("Error getting account", error);
          throw error;
        }));

      isConnected = true;
    };

    const hasWalletProviderInstalled = () => (window as any).ethereum
    const setOnChangeHandler = (refreshFn: () => Promise<void>) => {
        provider.on('accountsChanged', async (accounts: string[]) => {
            accountId = accounts[0]
            if (accounts.length === 0) {    
                await refreshFn()
            }
        })
    }

    return {
        getNetwork: () => network,
        getAccountId: () => accountId,
        isConnected: () => isConnected,
        connectWallet,
        getProvider: () => provider,
        hasWalletProviderInstalled,
        setOnChangeHandler,
        switchAccount
    }
}

export default walletProvider
